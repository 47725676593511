@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');
body {
  font-family: "Rubik", sans-serif;
  background-color: #4BCBBC;
}

code {
  font-family: "Rubik", sans-serif;
}
