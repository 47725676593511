nav{
	font-family: "Rubik", sans-serif;
	margin-top: 10px;
	position: sticky;
	top: -1px;
	background-color: #4BCBBC;
	z-index: 1000;
}
.nav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;
}
.nav .navbar-items{
	display: flex;
}
.nav .navbar-items a{
	padding: 25px 18px;
	text-decoration: none;
	color: #D9E1F4;
}
.nav .navbar-items a:hover{
	color: white;
}
.navbar-fb-link{
	display: flex;
	gap: 10px;
	align-items: center;
}
.navbar-fb-link .apply{
	padding: 15px 21.14px;
	border-radius: 24px;
	border: 2px solid #F53855;
	color: #F53855;
	text-decoration: none;
	font-weight: 500;
	transition: 0.2s;
}
.navbar-fb-link .apply:hover{
	background-color: #F53855;
	color: white;
}
.navbar-fb-link a i{
	font-size: 20px;
	color: #D9E1F4;
}
.navbar-fb-link a i:hover{
	color: white;
}
.navbar-fb-link a:nth-child(3){
	margin-right: 10px;
}
/* Мобильная версия */
.menu{
	display: none;
	height: 65px;
}
.menu h2{
	float: right;
	margin-top: -20px;
	margin-right: 10px;
	color: #D9E1F4;
}
.burger-checkbox {
	position: absolute;
	visibility: hidden;
  }
  .burger {
	position: relative;
	z-index: 1;
	cursor: pointer;
	display: block;
	position: relative;
	border: none;
	background: transparent;
	width: 40px;
	height: 26px;
	left: 20px;
	top: 20px;
  }
  .burger::before,
  .burger::after {
	content: '';
	left: 0;
	position: absolute;
	display: block;
	width: 100%;
	height: 4px;
	border-radius: 10px;
	background: white;
  }
  .burger::before {
	top: 0;
	box-shadow: 0 11px 0 white;
	transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
  }
  .burger::after {
	bottom: 0;
	transition: bottom .3s .15s, transform .3s;
  }
  .burger-checkbox:checked + .burger::before {
	top: 11px;
	transform: rotate(45deg);
	box-shadow: 0 6px 0 rgba(0,0,0,0);
	transition: box-shadow .15s, top .3s, transform .3s .15s;
  }
  .burger-checkbox:checked + .burger::after {
	bottom: 11px;
	transform: rotate(-45deg);
	transition: bottom .3s, transform .3s .15s;
  }
  .menu-list {
	top: -9px;
	left: 0;
	position: absolute;
	display: grid;
	gap: 12px;
	padding: 42px 0;
	margin: 0;
	background: #F53855;
	list-style-type: none;
	transform: translateX(-100%);
	transition: .3s;
	width: 200px;
	border-top-right-radius: 24px;
	border-bottom-right-radius: 24px;
  }
  .menu-item {
	display: block;
	padding: 8px;
	color: white;
	font-size: 18px;
	text-align: center;
	text-decoration: none;
  }
  .menu-item:hover {
	background: rgba(255,255,255,.2);
	text-decoration: none;
	color: #D9E1F4;
  }
  .burger-checkbox:checked ~ .menu-list {
	transform: translateX(0);
  }
  @media (width <= 950px) {
	.nav{
		display: none;
	}
	.menu{
		display: block;
	}
  }