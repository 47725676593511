.pad{
  margin-top: 150px;
}
.about-all h2{
  text-align: left;
  margin-bottom: 30px;
  padding-left: 35px;
  font-weight: bold;
}
.about-flex{
  border-radius: 8px;
  background-color: #1b2e35;
  color: #F1F3F5;
  display: flex;
  justify-content: space-between;
}
.about-flex .about-content{
  width: 45%;
  padding: 55px 35px;
  text-align: left;
}
.about-content b{
  font-size: 26px;
  font-family: "Sigmar One", sans-serif;
  font-weight: 500;
  color: #F53855;
}
.about-card{
  width: 55%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  padding-right: 20px;
  /* padding-left: 40px; */
}
.about-item{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  top: -60px;
  height: 150px;
}
.ai1{
  background-color: #e0ff95;
  color: #1b2e35;
  box-shadow: rgb(152, 169, 56) 0px 20px 30px -10px;
}
.ai2{
  background-color: #A9C7F9;
  color: #1b2e35;
  box-shadow: rgb(56, 152, 169) 0px 20px 30px -10px;
}
.ai3{
  background-color: #4BCBBC;
  color: #1b2e35;
  box-shadow: rgb(60, 202, 169) 0px 20px 30px -10px;
}
.ai4{
  background-color: #F53838;
  color: #F1F3F5;
  height: 200px;
  box-shadow: rgb(169, 82, 56) 0px 20px 30px -10px;
}
@media (width <= 1000px) {
  .pad{
    margin-top: 60px;
  }
  .about-flex{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-content, .about-card{
    width: 90% !important;
  }
  .about-card {
    padding: 0 0 40px;
  }
  .about-item{
    height: 150px;
    top: 0;
  }
}
@media (width <= 670px) {
  .about-card{
    grid-template-columns: 1fr;
  }
}
/* Основные стили футера */
.footer {
    width: 100%;
    background-color: #1b2e35;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
  .footer-logo {
    width: 300px;
    height: auto;
    margin: 36px 0 12px;
  }
  
  .footer-contacts,
  .footer-address {
    text-align: center;
  }
  .footer-address{
    margin-bottom: 36px;
  }
  .footer-contacts h4,
  .footer-address h4 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 8px;
    margin-top: 30px; /*Удалить после добавления logo*/
  }
  
  .footer-contacts p,
  .footer-address p {
    font-size: 14px;
    color: #ecebeb;
    margin: 4px 0;
  }
  
  .footer-socials {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 8px;
  }
  
  .footer-socials a {
    color: #234E6F;
    font-size: 20px;
    transition: color 0.3s ease;
  }
  
  .footer-socials a:hover {
    color: #0f202d;
  }
  
  /* Адаптивность */
  @media (max-width: 768px) {
    .footer {
      padding: 15px;
    }
  
    .footer-content {
      gap: 12px;
    }
  
    .footer-contacts h4,
    .footer-address h4 {
      font-size: 16px;
    }
  
    .footer-contacts p,
    .footer-address p {
      font-size: 13px;
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      padding: 10px;
    }
  
    .footer-logo {
      width: 200px;
    }
  
    .footer-contacts h4,
    .footer-address h4 {
      font-size: 14px;
    }
  
    .footer-contacts p,
    .footer-address p {
      font-size: 12px;
    }
  
    .footer-socials a {
      font-size: 18px;
    }
  }
  