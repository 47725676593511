@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.header-flex{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 600px;
    margin-bottom: 100px;
    font-family: "Rubik", sans-serif;
    /* padding-top: 80px; */
}
.header-content{
    width: 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}
.header-content h1{
    font-family: "Sigmar One", sans-serif;
    font-size: 4rem;
    color: #234E6F;
    margin-bottom: 0px;
    margin-top: 0;
    padding: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #1b2e35;
}
.header-content h1 i{
    transform: rotate(90deg);
    font-size: 2.8rem;
    display: inline-block;
    color: #F53855;
    font-style: normal;
}
.header-content h1 b{
    letter-spacing: 0.10em;
}
.header-content h1 img{
    width: 150px;
    height: 40px;
    margin: 5px 5px 0 5px;
}
.header-content h3{
    font-size: 1.4rem;
    color: #F1F3F5;
    margin-bottom: 25px;
    padding-left: 5px;
}
.header-content p{
    letter-spacing: 0.05em;
    color: #F1F3F5;
    padding-left: 5px;
}
.header-content a{
    width: 200px;
    text-decoration: none;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    background-color: #F53838;
    color: white;
    margin-top: 15px;
    transition: 0.1s;
}
.header-content a:hover{
    background-color: #f3282800;
    border: 2px solid #F53838;
    color: #F53838;
}
.header-gif{
    width: 50%;
}
.header-gif img{
    width: 100%;
}
@media (width<=900px) {
    .header-flex{
        flex-direction: column-reverse;
        height: auto;
    }
    .header-content{
        width: 100%;
        align-items: center;
        text-align: center;
        padding: 0;
    }
    .header-content h1{
        font-size: 3rem;
    }
    .header-content h1 i{
        font-size: 2rem;
    }
    .header-gif{
        width: 100%;
        margin-top: 10px;
    }
}
.searching_bar{
    width: 128px;
}
@media (width <= 370px) {
    .header-content p{
        font-size: 0.9rem;
    }
}
@media (width<=500px) {
    .header-content{
        width: 100%;
        align-items: center;
        text-align: center;
        padding: 0;
    }
    .header-content h1{
        font-size: 2rem;
    }
    .header-content h1 i{
        font-size: 1.5rem;
    }
    .header-content h1 .searching_bar{
        width: 98px;
        height: 25px;
    }
    .header-gif{
        width: 100%;
        margin-top: 10px;
    }
}

.header-bottom{
    padding: 20px 5px 0;
    /* position: relative;
    top: 50px; */
}
.header-bottom-items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #fff;
    padding: 40px 0;
    border-radius: 8px;
}
.hb-item{
    padding: 20px;
}
.hb-item-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.hb-item:first-child, .hb-item:nth-child(2){
    border-right: 1px solid rgba(151, 151, 151, 0.599);
}
.hb-icon{
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    background-color: #FFECEC;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hb-icon i{
    font-size: 20px;
    color: #F53838;
}
.hb-text{
    text-align: left;
    font-family: "Rubik", sans-serif;
}
.hb-text b{
    font-size: 24px;
}
.hb-textt{
    width: auto;
}
@media (width<=780px){
    .header-bottom-items{
        display: grid;
        grid-template-columns: 1fr;
        background-color: #ffffff00;
        gap: 15px;
    }
    .hb-item{
        /* border: none !important; */
        /* padding: 0px ; */
        background-color: #fff;
        width: 100%;
        border-radius: 8px;
        margin: auto;
    }
    .hb-item-flex{
        gap: 20px;
    }
    .hb-text{
        font-size: 14px;
    }
    .hb-text b{
        font-size: 20px;
    }
    .hb-icon{
        width: 60px !important;
        height: 60px !important;
    }
    .hb-textt{
        /* border: 1px solid black; */
        width: 60%;
    }
} 
@media (width<=480px){
    .hb-item{
        width: 100%;
        padding: 20px 30px;
    }
} 