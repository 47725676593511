.courses{
    margin-bottom: 40px;
    padding: 60px 0 100px;
}
.courses h2{
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 30px;
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    color: #333;
}
.cards{
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
}

.card {
    /* width: 0px; */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .card:hover{
    background-color: #fff;
  }
  .card-image {
    width: 100%;
    height: 220px;
    background-color: #d4f5d4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

  }
  .card-image img{
    width: 100%;
    height: 220px;
    border-radius: 8px;
    object-fit: cover;
  }
  .card-image i {
    font-size: 48px;
    color: #8bc34a;
  }
  
  .card-content {
    padding: 16px 5px;
    text-align: left;
    width: 100%;
  }
  
  .card-heading {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 8px 0;
  }
  
  .card-description {
    font-size: 14px;
    color: #555;
    margin: 0;
  }
  .card-duration{
    margin-top: 10px;
    margin-bottom: 0;
  }
  .card-price{
    margin-bottom: 0;
  }
  
  .card-action {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #8bc34a;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-top: 1px solid #ddd;
    transition: background-color 0.3s ease;
    border-radius: 8px;
  }
  
  .card-action:hover {
    background-color: #6b9838;
  }
  @media (600px <= width <= 1000px) {
    .cards{
        grid-template-columns: repeat(2, 1fr);
    }

}
@media (width<=600px) {
    .cards{
        grid-template-columns: 1fr;
    }}

    .loading-spinner {
      text-align: center;
      font-size: 18px;
      padding: 20px;
    }
    
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }
    
    .modal-content {
      background: #328a80;
      padding: 20px;
      border-radius: 16px;
      max-width: 900px;
      width: 90%;
      position: relative;
      color: rgb(243, 243, 243);
    }
    .modal-content strong{
      color: #ffffff;
    }
    .mod-flex{
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
    .mod-flex aside{
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .mod-flex aside h2{
      margin: 0 0 10px;
      color: rgb(255, 255, 255);
    }
    .modal-image {
      width: 40%;
      max-width: 300px;
      height: 160px;
      object-fit: cover;
      margin: 0 20px 20px 0;
      border-radius: 16px;
    }
    .mod-description{
      text-align: left;
    }
    .modal-additional-photos{
      display: flex;
      gap: 10px;
      margin-top: 10px;
    }
    .modal-additional-photo {
      width: 150px;
      height: 100px;
      object-fit: cover;
      border-radius: 16px;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 24px;
      cursor: pointer;
    }

    .modal-content article{
      display: flex;
      gap: 25px;
    }
    @media (width<=866px) {
      .mod-flex{
        flex-direction: column;
        margin-bottom: 20px;
      }
      .modal-image{
        width: 100%;
        max-width: 100%;
        margin-top: 10px;
      }
      .mod-flex aside {
        width: 100%;
      }
      .modal-content article{
        flex-wrap: wrap;
        gap: 0px 15px;
      }
      .modal-content{
        height: 80vh;
        overflow-x: hidden;
      }
    }
    @media (width<=570px) {
      .modal-additional-photos{
        display: none;
      }
      .mod-flex aside h2{
          font-size: 1.6em;
      }
    }
.form .form-heading{
  text-align: left;
  margin-left: 0;
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
  color: #0f202d;
}
.form .agreement{
  margin-left: 5px;
}
.form .submit-button{
  width: 100%;
}
.form{
  border-top: 1px solid rgb(146, 146, 146);
  padding: 20px 0px 0px !important;
  margin-top: 10px;
}
.not-courses{
  text-align: left;
}